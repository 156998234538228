import { useUserStore } from '@/stores';
import axios, { AxiosError } from 'axios';
import type {
  ApiDataException,
  SetImportedContactsResponseData,
  GetImportedContactsResponseData,
  ContactSwitch,
  ProviderImportedContact,
  SuccessResponseData,
  StoreContactResponseData,
  EditContactResponseData,
} from '@/types';
import { EndpointApi } from '@/enums';
import { useContact } from '@/composables';
import type { ContactDb } from '@/utils/db/types';

export async function useNewImportedContacts(contacts: ContactSwitch[]) {
  if (contacts.length === 0) return;
  try {
    const response = await axios.post<SetImportedContactsResponseData>(
      EndpointApi.NewImportedContacts,
      { contacts },
    );
    await useGetImportedContacts();
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useGetImportedContacts() {
  const userStore = useUserStore();
  const { saveOrUpdateContactDb, setContacts } = useContact();
  try {
    const response = await axios.get<GetImportedContactsResponseData>(
      EndpointApi.GetImportedContacts,
    );
    const contacts = response.data.contacts as unknown as ContactDb[];
    if (contacts.length === 0) return;
    for await (const contact of contacts) {
      contact.id =
        contact.id ??
        Number(`${contact.user_id}${userStore.currentAccount.accountCode}`);
      contact.hash = `${userStore.user.userId}+${userStore.currentAccount.accountCode}`;
      saveOrUpdateContactDb([contact]);
    }

    await setContacts();

    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useDeleteImportedContactsByProvider(
  provider: ProviderImportedContact,
) {
  const userStore = useUserStore();
  const { removeContactDbByProvider } = useContact();

  try {
    const response = await axios.post<SuccessResponseData>(
      EndpointApi.DeleteImportedContacts,
      {
        provider,
      },
    );
    userStore.removeImportedContactsByProvider(provider);
    removeContactDbByProvider(provider);
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useStoreContact(contact: ContactSwitch) {
  const userStore = useUserStore();
  const { saveOrUpdateContactDb } = useContact();
  try {
    const response = await axios.post<StoreContactResponseData>(
      EndpointApi.StoreContact,
      contact,
    );
    userStore.setImportedContacts(response.data.contact);
    userStore.sortImportedContactsByNameAsc();

    const newContact = response.data.contact as unknown as ContactDb;
    newContact.hash = `${userStore.user.userId}+${userStore.currentAccount.accountCode}`;

    await saveOrUpdateContactDb([newContact]);
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useEditContact(contact: ContactSwitch, id: number) {
  const userStore = useUserStore();
  const { saveOrUpdateContactDb } = useContact();
  try {
    const response = await axios.put<EditContactResponseData>(
      `${EndpointApi.Contact}/${id}/edit`,
      contact,
    );
    userStore.updateContact(id, response.data.contact);
    userStore.sortImportedContactsByNameAsc();

    const newContact = response.data.contact as unknown as ContactDb;
    newContact.hash = `${userStore.user.userId}+${userStore.currentAccount.accountCode}`;

    await saveOrUpdateContactDb([newContact]);
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useEditDirectoryContact(
  contact: ContactSwitch,
  id: number,
) {
  const userStore = useUserStore();
  const { saveOrUpdateContactDb, removeContactById } = useContact();

  try {
    const response = await axios.put<EditContactResponseData>(
      `${EndpointApi.Contact}/edit-directory`,
      contact,
    );
    userStore.updateContact(id, response.data.contact);
    userStore.sortImportedContactsByNameAsc();

    await removeContactById(id);

    const newContact = response.data.contact as unknown as ContactDb;
    newContact.hash = `${userStore.user.userId}+${userStore.currentAccount.accountCode}`;

    await saveOrUpdateContactDb([newContact]);
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useDeleteContact(id: number) {
  const userStore = useUserStore();
  const { removeContactById } = useContact();
  try {
    const response = await axios.delete<SuccessResponseData>(
      `${EndpointApi.Contact}/${id}/delete`,
    );
    userStore.deleteContact(id);

    await removeContactById(id);
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}
